<template>
  <el-select v-bind="$attrs" popper-class="elv-base-single-choice-select-popper" class="elv-base-single-choice-select">
    <el-option
      v-for="item in props.options"
      :key="isString(item.value) ? item.value : item.label"
      :label="transformI18n(item.label)"
      :value="item.value"
      :disabled="item?.isDisabled || false"
    >
      <img v-if="item.icon" :src="item.icon" :alt="item.alt" />
      <SvgIcon
        v-if="item?.svgName"
        :name="item?.svgName"
        :width="item?.svgWidth ?? '14px'"
        :height="item?.svgWidth ?? '14px'"
      />
      {{ transformI18n(item.label) }}<span v-if="item?.isDeleted" class="is-deleted">deleted</span>
    </el-option>
    <template #label="{ label, value }">
      <img v-if="currentIcon(value).value" :src="currentIcon(value).value" :alt="label" />
      <span>{{ label }}</span>
    </template>

    <template v-if="props.prefixText" #prefix>
      <span v-if="props.prefixText">{{ props.prefixText }}</span>
    </template>
  </el-select>
</template>
<script setup lang="ts">
import { transformI18n } from '@/i18n/index'
import { useComputedHook } from '@/hooks/useComputedHook'
import { find, uniqBy, isString, cloneDeep } from 'lodash-es'

const props = defineProps({
  prefixText: {
    type: String,
    default: ''
  },
  options: {
    type: Array as () => ElvSelectOptionType[],
    default: () => []
  },
  width: {
    type: String,
    default: 'fit-content'
  },
  isRemoteIcon: {
    type: Boolean,
    default: false
  }
})

const attrs: any = useAttrs()
const historyOptions = ref<ElvSelectOptionType[]>([])

/**
 * @description: 获取当前选项的icon
 * @param {string} value
 * @return {string}
 */
const currentIcon: any = useComputedHook((value: string) => {
  if (props.isRemoteIcon && !!historyOptions.value.length) {
    return find(historyOptions.value, { value })?.icon || ''
  }
  return find(props.options, { value })?.icon || ''
})

watch(
  [() => props.options],
  () => {
    if (attrs?.multiple === '' && typeof attrs?.['remote-method'] === 'function') {
      const list = [...cloneDeep(props.options), ...cloneDeep(historyOptions.value)]
      historyOptions.value = uniqBy(list, 'id')
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  historyOptions.value = []
})
</script>

<style lang="scss">
.elv-base-single-choice-select {
  display: flex;
  align-items: center;

  &.el-select {
    width: fit-content;

    .el-select__wrapper {
      width: v-bind('props.width');

      .el-select__selection {
        width: fit-content;

        &.is-near {
          margin-left: 0px;
        }

        .el-select__placeholder {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            display: block;
            margin-right: 4px;
          }
        }
      }

      .el-tag {
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        display: flex;
        padding: 0px 4px 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 2px;
        box-sizing: border-box;
        width: fit-content;

        .el-tag__content {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          .el-select__tags-text {
            display: flex;
            align-items: center;
          }

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }

        .el-icon.el-tag__close {
          width: 12px;
          height: 12px;
          color: #aaafb6;
          margin-left: 2px;

          &:hover {
            color: #fff;
            background-color: #909399;
          }
        }
      }

      .el-select__prefix {
        img {
          width: 16px;
          height: 16px;
          display: block;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.elv-base-single-choice-select-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    img {
      width: 18px;
      height: 18px;
    }

    svg {
      margin-right: 8px;
    }

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    &.is-selected:after {
      display: none;
    }

    .is-deleted {
      display: flex;
      height: 19px;
      padding: 6px 8px;
      align-items: center;
      box-sizing: border-box;
      color: #fb212f;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 14px;
      background: #edf0f3;
      margin-left: 8px;
    }
  }
}
</style>
